import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { AdvancedImage } from "@cloudinary/react"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { Cloudinary } from "@cloudinary/url-gen"
import { Parallax } from "react-scroll-parallax"

const CaseStudiesSlider = ({ sendata }) => {
  const d = sendata
  const useDefault = sendata
  let swiperData = []
  const boxRef = useRef()

  if (d !== null) {
    swiperData = d
  }

  const [aniBoxStart, setAniBoxStart] = useState()
  const [aniBoxEnd, setAniBoxEnd] = useState()

  const getPosition = () => {
    setAniBoxStart(boxRef.current.offsetTop - 600)
    setAniBoxEnd(boxRef.current.offsetTop - 300)
  }

  useEffect(() => {
    getPosition()
  }, [])

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const mapClients = swiperData.map((client, index) => {
    const imgUri =
      client.sharedCaseStudiesClientsCaseStudy.cfClientsSingleAll
        .clientGlobalSharedImage.mediaDetails.file

    const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

    theImage.quality("70")
    theImage.resize(fill().width(1152).height(668))
    theImage.format("auto")

    let doParallax = 0

    if (index === 0) {
      doParallax = 15
    }

    return (
      <SwiperSlide key={index} className={`swiper-slide d-flex flex-column`}>
        <Parallax
          speed={5}
          translateY={[doParallax, 0]}
          startScroll={aniBoxStart}
          endScroll={aniBoxEnd}
        >
          <div>
            <Link
              to={client.sharedCaseStudiesClientsCaseStudy.uri}
              title={`${client.sharedCaseStudiesClientsCaseStudy.title} case study`}
            >
              <AdvancedImage
                cldImg={theImage}
                alt={
                  client.sharedCaseStudiesClientsCaseStudy.cfClientsSingleAll
                    .clientGlobalSharedImage.altText
                }
                className={`mw-100`}
              />
            </Link>
            <p className="mt-3 mb-2 color-light-grey">
              {client.sharedCaseStudiesClientsCaseStudy.title}
            </p>
            <Link
              to={client.sharedCaseStudiesClientsCaseStudy.uri}
              title={`${client.sharedCaseStudiesClientsCaseStudy.title} case study`}
            >
              <h3 className="lower">
                {
                  client.sharedCaseStudiesClientsCaseStudy.cfClientsSingleAll
                    .singleClientHeroIntro
                }
              </h3>
            </Link>
          </div>
        </Parallax>
      </SwiperSlide>
    )
  })

  return (
    <div className="container mb-6" ref={boxRef}>
      <div className="row mb-2 mb-md-5">
        <div className="col-12 col-lg-6">
          <h2>Who we work with </h2>
        </div>
        <div className="d-none">
          <div className="col-6 d-lg-flex justify-content-end align-items-center">
            <Link
              to="/our-work/"
              className="ani_grey_arrow right_aligned"
              title="All Case Studies"
            >
              All Case Studies
            </Link>
          </div>
        </div>
      </div>
      <Swiper
        className="case_studies"
        slidesPerView={1.2}
        spaceBetween={20}
        breakpoints={{
          767: {
            slidesPerView: 2.2,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }}
      >
        {useDefault === null && (
          <>
            <SwiperSlide className={`swiper-slide d-flex flex-column`}>
              <Parallax
                speed={5}
                translateY={[15, 0]}
                startScroll={aniBoxStart}
                endScroll={aniBoxEnd}
              >
                <div>
                  <Link
                    to="/our-work/royal-london/"
                    title="royal london case study"
                  >
                    <img
                      src="https://res.cloudinary.com/dvmdv4ttu/image/upload/q_70/c_fill,w_1152,h_668/f_auto/img/royal-london-cs-logo_sdcpwe.png"
                      alt="Sainsbury's Bank"
                      className="mw-100"
                    />
                  </Link>
                  <p className="mt-3 mb-2 color-light-grey">Royal London</p>
                  <Link
                    to="/our-work/royal-london/"
                    title="royal london case study"
                  >
                    <h3 className="lower">
                      Helping customers plan for their future
                    </h3>
                  </Link>
                </div>
              </Parallax>
            </SwiperSlide>
            <SwiperSlide className={`swiper-slide d-flex flex-column`}>
              <Link
                to="/our-work/imperial-college-london/"
                title="imperial college london case study"
              >
                <img
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/q_70/c_fill,w_1152,h_668/f_auto/img/imperial_college_london_rbzqgk.png"
                  alt="Imperial College London"
                  className="mw-100"
                />
              </Link>
              <p className="mt-3 mb-2 color-light-grey">
                Imperial College London
              </p>
              <Link
                to="/our-work/imperial-college-london/"
                title="imperial college london case study"
              >
                <h3 className="lower">Transforming diabetic foot care</h3>
              </Link>
            </SwiperSlide>
            <SwiperSlide className={`swiper-slide d-flex flex-column`}>
              <Link to="/our-work/iberdrola/" title="iberdrola case study">
                <img
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/q_70/c_fill,w_1152,h_668/f_auto/img/iberdrola-cs-logo_tws014.png"
                  alt="Iberdrola"
                  className="mw-100"
                />
              </Link>
              <p className="mt-3 mb-2 color-light-grey">Iberdrola</p>
              <Link to="/our-work/iberdrola/" title="iberdrola case study">
                <h3 className="lower">
                  Revolutionising the electric vehicle experience
                </h3>
              </Link>
            </SwiperSlide>
          </>
        )}

        {useDefault !== null && <>{mapClients}</>}
      </Swiper>
    </div>
  )
}

export default CaseStudiesSlider
