// extracted by mini-css-extract-plugin
export var course = "ads-ver-two-form-module--course--a538e";
export var date = "ads-ver-two-form-module--date--fe550";
export var drop_select = "ads-ver-two-form-module--drop_select--72904";
export var event_banner = "ads-ver-two-form-module--event_banner--5f037";
export var form_control = "ads-ver-two-form-module--form_control--99b50";
export var initial = "ads-ver-two-form-module--initial--ffe56";
export var initial_intro_title = "ads-ver-two-form-module--initial_intro_title--41c43";
export var initial_tc = "ads-ver-two-form-module--initial_tc--e3155";
export var invalid_feedback = "ads-ver-two-form-module--invalid_feedback--275db";
export var invalid_feedback_input = "ads-ver-two-form-module--invalid_feedback_input--0591a";
export var mfSub = "ads-ver-two-form-module--mfSub--4091b";
export var processing = "ads-ver-two-form-module--processing--2cdf1";
export var processingBtn = "ads-ver-two-form-module--processingBtn--424f2";
export var processing_intro_title = "ads-ver-two-form-module--processing_intro_title--a2c7d";
export var processing_tc = "ads-ver-two-form-module--processing_tc--de021";
export var radio_btn = "ads-ver-two-form-module--radio_btn--c54c3";
export var send_icon = "ads-ver-two-form-module--send_icon--44437";
export var success = "ads-ver-two-form-module--success--c8404";
export var successBtn = "ads-ver-two-form-module--successBtn--c514c";
export var success_intro_title = "ads-ver-two-form-module--success_intro_title--9f086";
export var success_message = "ads-ver-two-form-module--success_message--e869d";
export var success_message_init = "ads-ver-two-form-module--success_message_init--634e8";
export var ts_cs = "ads-ver-two-form-module--ts_cs--f7e81";