import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import * as style from "./services-hero.module.css"
import { cloudImage, isSafariLess16 } from "../utils.js"

const HeroServices = ({ pagedata, bgimg, showform }) => {
  let title = pagedata.heroblocksimpleleftTitle || ""
  let intro = pagedata.heroblocksimpleleftIntroText || ""

  const bgImgURL = cloudImage(
    bgimg.mediaDetails.file,
    bgimg.mimeType,
    1920,
    true
  )

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const isSafariV16 = isSafariLess16()

  return (
    <div
      className="d-flex align-items-center darkgrey_bg bg_image flex-column justify-items-center mb-6"
      style={{
        backgroundImage: bgImgURL,
        height: "100vh",
        minHeight: "700px",
      }}
    >
      <div className="container flex-grow-1">
        <div
          id="hide-row-when-nav-open"
          className="row h-100 align-content-center"
        >
          <span className="color-white">
            <div className="col-12 mb-3 mb-lg-5 animated-headline z_index_top">
              <div className="animated-headline-wrap">
                <h1 className="new_style">
                  <span
                    className={`new_style_addit ${
                      isSafariV16 ? "safari_16" : ""
                    }`}
                  >
                    {title}
                  </span>
                </h1>
              </div>
            </div>
            <div className="col-12 col-xl-5 z_index_top">
              <h3
                className="lower header-fade-in"
                dangerouslySetInnerHTML={{ __html: intro }}
              />
            </div>
            {showform === true && (
              <div className="col-12 mt-3 pt-4 z_index_top header-fade-in">
                <a
                  href="#contactForm"
                  title="Schedule a free consultation"
                  className={`${style.white_button} d-inline-block`}
                >
                  Schedule a free consultation
                  <img
                    src="/assets/img/arrow-grey.svg"
                    alt="grey arrow icon"
                    className="ms-3"
                  />
                </a>
              </div>
            )}
          </span>
        </div>
      </div>
      {pagedata.heroblocksimpleleftHeroLogos !== null && (
        <div className="container z_index_top mb-5">
          <div className="row">
            <div
              className={`d-flex align-items-center justify-content-start justify-content-lg-between`}
              style={{
                flexWrap: "inherit",
              }}
            >
              {pagedata.heroblocksimpleleftHeroLogos.map((logo, index) => {
                let imgUri = logo.heroblocksimpleleftHeroLogo.mediaDetails.file
                let altText = logo.heroblocksimpleleftHeroLogo.altText

                let theImage = cld.image(
                  `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
                )
                theImage.format("auto")

                let imgHeight =
                  logo.heroblocksimpleleftHeroLogo.mediaDetails.height +
                  logo.heroblocksimpleleftHeroLogo.mediaDetails.height * 1.25
                let imgWidth =
                  logo.heroblocksimpleleftHeroLogo.mediaDetails.width
                let maxHeight = ""
                if (imgHeight > imgWidth) {
                  maxHeight = style.serv_ads_client_logo_h
                }
                return (
                  <AdvancedImage
                    cldImg={theImage}
                    key={index}
                    alt={altText}
                    className={`${style.serv_ads_client_logo} ${maxHeight} mw-sm-100`}
                  />
                )
              })}
            </div>
          </div>
        </div>
      )}
      <div className="dark_image_overly"></div>
    </div>
  )
}

export default HeroServices
