import React from "react"
import { Link } from "gatsby"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"

const OurStrategy = props => {
  const {
    strategyTitle1,
    strategyTitle2,
    strategyTitle3,
    strategyTitle4,
    strategyTitle51,
    strategyTitle61,
    strategyContent1,
    strategyContent2,
    strategyContent3,
    strategyContent4,
    strategyContent51,
    strategyContent61,
  } = props.pagedata

  const serviceIcon_1_info = props.pagedata.strategy01Icon
  const serviceIcon_2_info = props.pagedata.strategy02Icon
  const serviceIcon_3_info = props.pagedata.strategy03Icon
  const serviceIcon_4_info = props.pagedata.strategy04Icon
  const serviceIcon_5_info = props.pagedata.strategy05Icon1
  const serviceIcon_6_info = props.pagedata.strategy06Icon1

  var serviceIcon_1_img
  var serviceIcon_2_img
  var serviceIcon_3_img
  var serviceIcon_4_img
  var serviceIcon_5_img
  var serviceIcon_6_img

  const serviceLinkText_1 =
    props.pagedata.strategy01DiscoverMoreLinkText || "Discover More"
  const serviceLinkText_2 =
    props.pagedata.strategy02DiscoverMoreLinkText || "Discover More"
  const serviceLinkText_3 =
    props.pagedata.strategy03DiscoverMoreLinkText || "Discover More"
  const serviceLinkText_4 =
    props.pagedata.strategy04DiscoverMoreLinkText || "Discover More"
  const serviceLinkText_5 =
    props.pagedata.strategy05DiscoverMoreLinkText || "Discover More"
  const serviceLinkText_6 =
    props.pagedata.strategy06DiscoverMoreLinkText || "Discover More"

  const serviceLink_1 = props.pagedata.strategy01DiscoverMoreLink
  const serviceLink_2 = props.pagedata.strategy02DiscoverMoreLink
  const serviceLink_3 = props.pagedata.strategy03DiscoverMoreLink
  const serviceLink_4 = props.pagedata.strategy04DiscoverMoreLink
  const serviceLink_5 = props.pagedata.strategy05DiscoverMoreLink
  const serviceLink_6 = props.pagedata.strategy06DiscoverMoreLink

  console.log(serviceLink_2)

  if (serviceIcon_1_info !== null) {
    serviceIcon_1_img = props.pagedata.strategy01Icon.mediaDetails.file
  } else {
    serviceIcon_1_img = "services-icon-cloud.png"
  }

  if (serviceIcon_2_info !== null) {
    serviceIcon_2_img = props.pagedata.strategy02Icon.mediaDetails.file
  } else {
    serviceIcon_2_img = "services-icon-cloud.png"
  }

  if (serviceIcon_3_info !== null) {
    serviceIcon_3_img = props.pagedata.strategy03Icon.mediaDetails.file
  } else {
    serviceIcon_3_img = "services-icon-cloud.png"
  }

  if (serviceIcon_4_info !== null) {
    serviceIcon_4_img = props.pagedata.strategy04Icon.mediaDetails.file
  } else {
    serviceIcon_4_img = "services-icon-cloud.png"
  }

  if (serviceIcon_5_info !== null) {
    serviceIcon_5_img = props.pagedata.strategy05Icon1.mediaDetails.file
  } else {
    serviceIcon_5_img = "services-icon-cloud.png"
  }

  if (serviceIcon_6_info !== null) {
    serviceIcon_6_img = props.pagedata.strategy06Icon1.mediaDetails.file
  } else {
    serviceIcon_6_img = "services-icon-cloud.png"
  }

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  let theImage_1 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${serviceIcon_1_img}`
  )
  let theImage_2 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${serviceIcon_2_img}`
  )
  let theImage_3 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${serviceIcon_3_img}`
  )
  let theImage_4 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${serviceIcon_4_img}`
  )
  let theImage_5 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${serviceIcon_5_img}`
  )
  let theImage_6 = cld.image(
    `${process.env.GATSBY_API_FOLDER_URL}${serviceIcon_6_img}`
  )

  return (
    <div className="container">
      <div className="row">
        {strategyTitle1 !== null && (
          <div className="col-12 col-lg-6 mb-6">
            <AdvancedImage
              cldImg={theImage_1}
              alt={serviceIcon_1_info.altText}
              className="services_icon mb-4 mw-100"
            />
            <div className="d-flex align-items-start align-items-xl-top mb-3">
              <span className="number me-2 me-md-3">01</span>
              <h2 className="mb-0 mt-2 mt-lg-0">{strategyTitle1}</h2>
            </div>
            <div className="col-12 col-lg-8">
              <p dangerouslySetInnerHTML={{ __html: strategyContent1 }} />
              {serviceLink_1 !== null && (
                <Link to={serviceLink_1.uri} title={serviceLinkText_1}>
                  <button
                    title={serviceLinkText_1}
                    className="ani_grey_arrow ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "#212529",
                      marginTop: "38px",
                      display: "block",
                    }}
                  >
                    {serviceLinkText_1}
                  </button>
                </Link>
              )}
            </div>
          </div>
        )}
        {strategyTitle2 !== null && (
          <div className="col-12 col-lg-6 mb-6">
            <AdvancedImage
              cldImg={theImage_2}
              alt={serviceIcon_2_info.altText}
              className="services_icon mb-4 mw-100"
            />
            <div className="d-flex align-items-start align-items-xl-top mb-3">
              <span className="number me-2 me-md-3">02</span>
              <h2 className="mb-0 mt-2 mt-lg-0">{strategyTitle2}</h2>
            </div>
            <div className="col-12 col-lg-8">
              <p dangerouslySetInnerHTML={{ __html: strategyContent2 }} />
              {serviceLink_2 !== null && (
                <Link to={serviceLink_2.uri} title={serviceLinkText_2}>
                  <button
                    title={serviceLinkText_2}
                    className="ani_grey_arrow ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "#212529",
                      marginTop: "38px",
                      display: "block",
                    }}
                  >
                    {serviceLinkText_2}
                  </button>
                </Link>
              )}
            </div>
          </div>
        )}
        {strategyTitle3 !== null && (
          <div className="col-12 col-lg-6 mb-6">
            <AdvancedImage
              cldImg={theImage_3}
              alt={serviceIcon_3_info.altText}
              className="services_icon mb-4 mw-100"
            />
            <div className="d-flex align-items-start align-items-xl-top mb-3">
              <span className="number me-2 me-md-3">03</span>
              <h2 className="mb-0 mt-2 mt-lg-0">{strategyTitle3}</h2>
            </div>
            <div className="col-12 col-lg-8">
              <p dangerouslySetInnerHTML={{ __html: strategyContent3 }} />
              {serviceLink_3 !== null && (
                <Link to={serviceLink_3.uri} title={serviceLinkText_3}>
                  <button
                    title={serviceLinkText_3}
                    className="ani_grey_arrow ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "#212529",
                      marginTop: "38px",
                      display: "block",
                    }}
                  >
                    {serviceLinkText_3}
                  </button>
                </Link>
              )}
            </div>
          </div>
        )}
        {strategyTitle4 !== null && (
          <div className="col-12 col-lg-6 mb-6">
            <AdvancedImage
              cldImg={theImage_4}
              alt={serviceIcon_4_info.altText}
              className="services_icon mb-4 mw-100"
            />
            <div className="d-flex align-items-start align-items-xl-top mb-3">
              <span className="number me-2 me-md-3">04</span>
              <h2 className="mb-0 mt-2 mt-lg-0">{strategyTitle4}</h2>
            </div>
            <div className="col-12 col-lg-8">
              <p dangerouslySetInnerHTML={{ __html: strategyContent4 }} />
              {serviceLink_4 !== null && (
                <Link to={serviceLink_4.uri} title={serviceLinkText_4}>
                  <button
                    title={serviceLinkText_4}
                    className="ani_grey_arrow ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "#212529",
                      marginTop: "38px",
                      display: "block",
                    }}
                  >
                    {serviceLinkText_4}
                  </button>
                </Link>
              )}
            </div>
          </div>
        )}
        {strategyTitle51 !== null && (
          <div className="col-12 col-lg-6 mb-6">
            <AdvancedImage
              cldImg={theImage_5}
              alt={serviceIcon_5_info.altText}
              className="services_icon mb-4 mw-100"
            />
            <div className="d-flex align-items-start align-items-xl-top mb-3">
              <span className="number me-2 me-md-3">05</span>
              <h2 className="mb-0 mt-2 mt-lg-0">{strategyTitle51}</h2>
            </div>
            <div className="col-12 col-lg-8">
              <p dangerouslySetInnerHTML={{ __html: strategyContent51 }} />
              {serviceLink_5 !== null && (
                <Link to={serviceLink_5.uri} title={serviceLinkText_5}>
                  <button
                    title={serviceLinkText_5}
                    className="ani_grey_arrow ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "#212529",
                      marginTop: "38px",
                      display: "block",
                    }}
                  >
                    {serviceLinkText_5}
                  </button>
                </Link>
              )}
            </div>
          </div>
        )}
        {strategyTitle61 !== null && (
          <div className="col-12 col-lg-6 mb-6">
            <AdvancedImage
              cldImg={theImage_6}
              alt={serviceIcon_6_info.altText}
              className="services_icon mb-4 mw-100"
            />
            <div className="d-flex align-items-start align-items-xl-top mb-3">
              <span className="number me-2 me-md-3">06</span>
              <h2 className="mb-0 mt-2 mt-lg-0">{strategyTitle61}</h2>
            </div>
            <div className="col-12 col-lg-8">
              <p dangerouslySetInnerHTML={{ __html: strategyContent61 }} />
              {serviceLink_6 !== null && (
                <Link to={serviceLink_6.uri} title={serviceLinkText_6}>
                  <button
                    title={serviceLinkText_6}
                    className="ani_grey_arrow ps-0"
                    style={{
                      background: "none",
                      border: "0",
                      color: "#212529",
                      marginTop: "38px",
                      display: "block",
                    }}
                  >
                    {serviceLinkText_6}
                  </button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default OurStrategy
