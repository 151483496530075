import React from "react"
import { Cloudinary } from "@cloudinary/url-gen"
import { AdvancedImage } from "@cloudinary/react"
import { lazyload, placeholder } from "@cloudinary/react"

const SingleTestimonial = props => {
  const { singleNamePosition, singleTestimonial, singleClientLogo } =
    props.pagedata

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const slug = singleClientLogo.mediaDetails.file
  const altText = singleClientLogo.altText

  const theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${slug}`)

  theImage.format("auto")

  return (
    <div
      className="color-white pt-5 pb-5"
      style={{ backgroundColor: "rgb(20, 19, 19)" }}
    >
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12 col-lg-5">
            <h3 className="mb-5">Client Testimonial</h3>
          </div>
          <div className="col-12 col-lg-7">
            <h3>"{singleTestimonial}"</h3>
            <p className="mt-5">{singleNamePosition}</p>
            <AdvancedImage
              cldImg={theImage}
              plugins={[
                lazyload({
                  rootMargin: "0px",
                  threshold: 0.25,
                }),
                placeholder({ mode: "blur" }),
              ]}
              alt={altText}
              className={`industries_client_logo_large mt-3`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleTestimonial
