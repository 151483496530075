import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { ParallaxProvider } from "react-scroll-parallax"
import { cloudImage } from "../components/utils.js"
import HeroServices from "../components/services-page/services-hero"
import OurThoughts from "../components/shared/our-thoughts"
import OurStrategy from "../components/services-page/our-strategy"
import SingleTestimonial from "../components/services-page/client-testimonial"
import AdsVersionTwoForm from "../components/ad-pages/ads-ver-two-form"
import CaseStudiesSlider from "../components/shared/case-studies"
import { seoHead } from "../components/utils.js"
import "swiper/css"
import "swiper/css/scrollbar"

export const query = graphql`
  query ($id: ID!, $pageId: Int) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        featuredImage {
          node {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfHeroBlockSimpleLeft {
          heroblocksimpleleftIntroText
          heroblocksimpleleftTitle
          heroblocksimpleleftHeroLogos {
            heroblocksimpleleftHeroLogo {
              altText
              mediaType
              mediaDetails {
                file
                width
                height
              }
            }
          }
        }
        cfSingleImage1 {
          imageToUse {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfTtextIntroOne {
          introTextOne
        }
        cfServiceStrategy {
          strategyContent1
          strategyContent2
          strategyContent3
          strategyContent4
          strategyContent51
          strategyContent61
          strategyTitle1
          strategyTitle2
          strategyTitle3
          strategyTitle4
          strategyTitle51
          strategyTitle61
          strategy01DiscoverMoreLink {
            ... on WPGraphQL_Page {
              uri
            }
          }
          strategy02DiscoverMoreLink {
            ... on WPGraphQL_Page {
              uri
            }
          }
          strategy03DiscoverMoreLink {
            ... on WPGraphQL_Page {
              uri
            }
          }
          strategy04DiscoverMoreLink {
            ... on WPGraphQL_Page {
              uri
            }
          }
          strategy05DiscoverMoreLink {
            ... on WPGraphQL_Page {
              uri
            }
          }
          strategy06DiscoverMoreLink {
            ... on WPGraphQL_Page {
              uri
            }
          }
          strategy01DiscoverMoreLinkText
          strategy02DiscoverMoreLinkText
          strategy03DiscoverMoreLinkText
          strategy04DiscoverMoreLinkText
          strategy05DiscoverMoreLinkText
          strategy06DiscoverMoreLinkText

          strategy01Icon {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          strategy02Icon {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          strategy03Icon {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          strategy04Icon {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          strategy05Icon1 {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
          strategy06Icon1 {
            altText
            mimeType
            mediaDetails {
              file
            }
          }
        }
        cfTestimonialSingle {
          singleHideTestimonial
          singleNamePosition
          singleTestimonial
          singleClientLogo {
            altText
            mediaDetails {
              file
            }
          }
        }
        cfTeamSlider {
          teamSliderTitle
          teamSliderMembers {
            teamSliderName
            teamSliderPosition
            teamSliderImage {
              altText
              mediaDetails {
                file
              }
            }
          }
        }
        cfSalesPerson {
          salesPersonAgentEmail
          salesPersonAgentName
          salesPersonAgentPosition
          salesPersonIntro
          salesPersonAgentPic {
            altText
            mediaDetails {
              file
            }
          }
        }
        servSingleAddForm {
          servSingUseTheForm
        }

        cfSharedCaseStudies {
          sharedCaseStudiesClients {
            sharedCaseStudiesClientsCaseStudy {
              ... on WPGraphQL_Page {
                title
                uri
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                    mimeType
                  }
                }
              }
            }
          }
        }
      }
      pages(where: { id: $pageId }) {
        nodes {
          cfThoughtsBlock {
            thoughtsBlogItem1 {
              __typename
              ... on WPGraphQL_Post {
                id
                title
                uri
                featuredImage {
                  node {
                    altText
                    mediaDetails {
                      file
                    }
                  }
                }
              }
            }
          }
          cfServicesCollaborators {
            collaboratorTitle
            collaborators {
              collaboratorName
              collaboratorSubtext
              collaboratorLinkUrl {
                ... on WPGraphQL_Post {
                  id
                  title
                  uri
                }
              }
              collaboratorImage {
                altText
                mediaDetails {
                  file
                }
              }
            }
          }
        }
      }
    }
  }
`

const PageTemplateServicesSingle = ({ data }) => {
  const page = data.wpgraphql.page
  const thoughts = data.wpgraphql.pages.nodes[0].cfThoughtsBlock

  const bgImgUrl = page.featuredImage.node

  // Intro block
  const introText = page.cfTtextIntroOne.introTextOne || ""

  const activateForm = page.servSingleAddForm.servSingUseTheForm
  const formBgImg = cloudImage(
    bgImgUrl.mediaDetails.file,
    bgImgUrl.mimeType,
    1920,
    true
  )

  const hideTestim = page.cfTestimonialSingle.singleHideTestimonial || false

  return (
    <>
      <Layout>
        <HeroServices
          pagedata={page.cfHeroBlockSimpleLeft}
          bgimg={bgImgUrl}
          showform={activateForm}
        />

        <div className="container mb-6">
          <div className="row">
            <div className="col-12 col-lg-9">
              <h3 className="lower">{introText}</h3>
            </div>
          </div>
        </div>

        <OurStrategy pagedata={page.cfServiceStrategy} />

        <ParallaxProvider>
          <CaseStudiesSlider
            sendata={page.cfSharedCaseStudies.sharedCaseStudiesClients}
          />
        </ParallaxProvider>

        {activateForm === true && (
          <div
            className="container-fluid"
            style={{ backgroundColor: "#1d1d1d" }}
          >
            <div className="row justify-content-center">
              <div
                id="contactForm"
                className="col-12"
                style={{
                  backgroundImage: formBgImg,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minHeight: "500px",
                }}
              ></div>
              <div
                className="col-12 col-sm-10 col-md-6 col-lg-5"
                style={{ marginTop: "-250px" }}
              >
                <AdsVersionTwoForm />
              </div>
            </div>
          </div>
        )}

        {!hideTestim && (
          <SingleTestimonial pagedata={page.cfTestimonialSingle} />
        )}

        <ParallaxProvider>
          <OurThoughts pagedata={thoughts} reversed="true" />
        </ParallaxProvider>
      </Layout>
    </>
  )
}

export default PageTemplateServicesSingle

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
